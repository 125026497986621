/*======= Counter Items v1  =======*/
.counter-items-v1 {
    .counter-item {
        .icon {
            font-size: 30px;
            width: 80px;
            height: 80px;
            text-align: center;
            line-height: 80px;
            color: $color-white;
            border-radius: 50%;
            background: rgba(#fff, 0.07);
            margin-bottom: 30px;
            position: relative;
            z-index: 1;
            transition: 0.3s;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                z-index: -1;
                background-color: $color-white;
                transform: scale(0);
                transition: 0.3s;
            }
        }

        .counter-wrap {
            display: flex;
            align-items: center;
            font-size: 42px;
            line-height: 1;
            color: $color-white;
        }

        .title {
            color: $color-white;
            margin-top: 15px;
            line-height: 1.4;
        }

        &:hover {
            .icon {
                color: $color-primary;

                &::after {
                    transform: scale(1);
                }
            }
        }
    }
}

/*======= Counter Items v2  =======*/
.counter-items-v2 {
    .counter-item {
        .counter-wrap {
            position: relative;
            z-index: 1;
            display: inline-block;
        }

        .counter {
            font-size: 85px;
            line-height: 0.8;

            color: $color-heading;
        }

        .suffix {
            font-size: 20px;
            top: -10px;
            position: absolute;
            right: -25px;
            line-height: 0.9;
            color: $color-secondary;
        }

        .title {
            padding-top: 10px;
            font-size: 18px;
            line-height: 1.4;
            font-family: $font-cs-medium;
            font-weight: 500;
        }

        &.counter-left {
            display: flex;
            align-items: center;

            .counter-wrap {
                min-width: 95px;
            }

            .title {
                padding-top: 0;
                padding-left: 25px;
            }
        }

        &.white-color {
            .title,
            .counter,
            .suffix {
                color: $color-white;
            }
        }
    }
}
