/*======= Projects =======*/
.recent-projects {
    .recent-project-item {
        .project-thumbnail {
            overflow: hidden;
            border-radius: 7px 7px 0 0;

            img {
                width: 100%;
                border-radius: 7px 7px 0 0;
            }
        }

        .project-content {
            border: 2px solid rgb(51 119 255 / 10%);
            border-radius: 0 0 7px 7px;
            border-top: 0;
            padding: 40px 35px;
            transition: 0.3s;
            background-color: $color-white;
        }

        .project-cat {
            color: $color-primary-4;
            margin-bottom: 15px;
            line-height: 1.3;
        }

        .title {
            font-size: 22px;
            letter-spacing: -1px;
            line-height: 1.4;
        }

        .read-more-btn {
            display: inline-block;
            padding: 15px 40px;
            font: {
                size: 15px;
                family: $font-cs-bold;
                weight: 700;
            }
            margin-top: 25px;
            background-color: $color-soft-grey;
            color: $color-heading;
            line-height: 1.4;
            transition: all 0.4s;
            vertical-align: middle;
            border-radius: 50px;

            i {
                margin-left: 10px;
            }

            &:hover {
                background-color: $color-primary-4;
            }
        }

        &:hover {
            .project-content {
                box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
                border-color: transparent;
            }

            .read-more-btn {
                background-color: $color-primary-4;
                color: $color-white;
            }
        }
    }
}
