/*======= Latest News v1 =======*/
.latest-news-v1 {
    .latest-news-box {
        background: $color-white;
        padding: 20px 20px 35px 20px;
        border-radius: 10px;

        .post-thumbnail {
            position: relative;

            img {
                border-radius: 10px;
                width: 100%;
            }
        }

        .post-tag {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            position: absolute;
            left: 15px;
            bottom: 0;
            z-index: 2;
            transform: translateY(50%);

            a {
                display: block;
                background-color: $color-primary;
                color: $color-white;
                padding: 8px 15px;
                border-radius: 7px;
                line-height: 1;
                font: {
                    size: 15px;
                    weight: 700;
                    family: $font-cs-bold;
                }
            }
        }

        .post-content {
            padding: 35px 15px 0px 15px;
        }

        .post-meta {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            margin-bottom: 15px;
            line-height: 1;

            a {
                font-size: 15px;
                color: $color-body;
                line-height: 1;

                font: {
                    size: 15px;
                    family: $font-cs-medium;
                    weight: 500;
                }

                i {
                    margin-right: 8px;
                }
            }
        }

        .title {
            font-size: 22px;
            margin-bottom: 28px;
            line-height: 1.3;

            a {
                color: $color-heading-2;
            }
        }

        .read-more-btn {
            background-color: #e5ecfc;
            font: {
                size: 15px;
                family: $font-cs-bold;
                weight: 700;
            }
            color: $color-heading-2;
            border-radius: 30px;
            line-height: 1.4;
            padding: 15px 30px;

            i {
                margin-left: 8px;
            }
        }

        &:hover {
            .read-more-btn {
                background-color: $color-heading;
                color: $color-white;
            }
        }
    }

    &.color-secondary {
        .latest-news-box {
            .post-tag a {
                background-color: $color-secondary;
            }

            &:hover {
                .read-more-btn {
                    background-color: $color-heading-2;
                    color: $color-white;
                }
            }
        }
    }
}

/*======= Latest News v2 =======*/
.latest-news-v2 {
    .latest-news-box {
        .post-thumbnail {
            overflow: hidden;
            border-radius: 7px;

            img {
                transition: 0.3s;
                width: 100%;
            }
        }

        .post-content {
            box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
            margin-left: 40px;
            padding: 20px 35px 25px;
            margin-top: -40px;
            position: relative;
            background-color: $color-white;
            z-index: 1;
            border-radius: 7px 0 7px 7px;
            transition: 0.3s;
        }

        .post-meta {
            margin-bottom: 15px;

            li a {
                color: #606060;
                line-height: 1;
                font: {
                    size: 15px;
                    family: $font-cs-medium;
                    weight: 500;
                }

                i {
                    margin-right: 8px;
                    color: $color-primary-3;
                }
            }
        }

        .title {
            font-size: 20px;
            margin-bottom: 15px;
            line-height: 1.4;

            a:hover {
                color: $color-primary-3;
            }
        }

        .post-author {
            img {
                margin-right: 12px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            a {
                color: #87888b;
                font: {
                    size: 18px;
                    family: $font-cs-medium;
                    weight: 500;
                }
            }
        }

        &:hover {
            .post-thumbnail img {
                transform: scale(1.1);
            }

            .post-content {
                margin-left: 0;
                border-top-left-radius: 0;
            }
        }
    }
}
