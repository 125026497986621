/*======= Newsletter Form =======*/
.newsletter-form {
    .input-field {
        position: relative;

        input {
            width: 100%;
            height: 95px;
            background-color: $color-white;
            border-radius: 45px;
            padding-left: 45px;
            padding-right: 250px;
            font: {
                size: 17px;
                weight: 500;
            }
        }

        .template-btn {
            position: absolute;
            right: 15px;
            bottom: 15px;
            top: 15px;
        }
    }

    &.form-two {
        .input-field {
            display: flex;
            justify-content: space-between;

            input {
                border-radius: 7px;
                height: 65px;
                padding-left: 30px;
                padding-right: 0;
                width: calc(100% - 230px);
            }

            .template-btn {
                position: unset;
            }
        }

        &.have-form-radius {
            input,
            .template-btn {
                border-radius: 50px;
            }
        }
    }
}

.newsletter-form-2 {
    position: relative;
    z-index: 1;

    input {
        box-shadow: 0px 10px 30px 0px rgba(188, 183, 255, 0.36);
        border-radius: 30px;
        width: 100%;
        font-size: 17px;
        padding: 0 70px 0 30px;
        height: 65px;
        background-color: $color-white;
    }

    button {
        height: 45px;
        width: 45px;
        line-height: 45px;
        text-align: center;
        padding: 0;
        position: absolute;
        right: 0;
        top: 10px;
        right: 10px;
        background-color: $color-primary;
        border: none;
        border-radius: 50%;
        color: $color-white;
        transition: 0.3s;

        &:hover {
            background-color: $color-heading;
        }
    }
}
