/*======= Brand Items =======*/
.brand-items {
    &.brand-effect-one {
        img {
            transition: 0.3s;
            opacity: 0.4;
            filter: grayscale(100%);

            &:hover {
                opacity: 1;
                filter: grayscale(0);
            }
        }
    }

    .brand-item {
        text-align: center;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }
}

.brands-item-grid {
    img {
        transition: 0.3s;
        filter: brightness(0.5);

        &:hover {
            filter: brightness(1);
        }
    }
}

.brand-sectioin-title {
    font: {
        size: 30px;
        family: $font-cs-medium;
        weight: 500;
    }
    line-height: 1.3;
    letter-spacing: -1px;

    span {
        color: $color-secondary;
    }
}
